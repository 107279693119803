<template>
<v-card flat>
    <transition name="fade" mode="out-in" key="0">
        <v-card flat class="d-flex justify-center rounded-lg" v-if="turnodatos.asignaciones.length" max-height="180" style="overflow: auto;overflow-x: overlay;">
            <v-list class="py-0 rounded-lg" min-width="200">
                <v-menu v-for="maquinista in turnodatos.asignaciones" :key="maquinista.id" class="mb-2" offset-y :disabled="!edit" rounded="lg" transition="slide-y-transition">
                    <template v-slot:activator="{ attrs, on }">
                        <v-card flat class="text-center">
                            <v-chip v-on="on" v-bind="attrs" outlined style="height: 40px;" :ripple="Edit" class="mb-1">
                                <v-avatar color="grey lighten-4" left size="30">
                                    <span class="grey--text text--darken-1 text-caption font-weight-medium turno-title">{{TipoMaquinsita(maquinista.tipo)}}</span>
                                </v-avatar>
                                <v-list-item-title class="text-subtitle-2 grey--text text--darken-1 text-capitalize mb-0">{{maquinista.nombre}}</v-list-item-title>
                            </v-chip>
                        </v-card>
                    </template>
                    <v-list class="pa-0 rounded-lg" dense>
                        <v-list-item @click="LiberarMaquinista(turnodatos.id, maquinista.id, fecha)">
                            <v-list-item-avatar class="mr-4" size="30" color="secondary">
                                <v-icon size="20" color="primary">mdi-run</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title class="grey--text text--darken-2">Liberar</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="AbrirEdicion(turnodatos, maquinista)">
                            <v-list-item-avatar class="mr-4" size="30" color="secondary">
                                <v-icon size="20" color="primary">mdi-playlist-edit</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title class="grey--text text--darken-2">Editar</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list>
        </v-card>
    </transition>
    <v-dialog v-model="dialog.asignar" persistent max-width="450" transition="fade-transition" class="rounded-lg" overlay-opacity="0">
        <v-card flat>
            <v-card-title class="pa-0 modal-title">
                <v-toolbar flat class="rounded-t-lg">
                    <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;">
                        <v-list-item class="pl-0 rounded-t-lg">
                            <v-list-item-avatar class="mr-2 rounded-lg" color="secondary" tile width="8" height="30" min-width="8" />
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline pb-0 mb-0">
                                    <v-toolbar-title class="pa-0 text-capitalize grey--text text--darken-2" style="line-height: 1.1;">Asignar Maquinista</v-toolbar-title>
                                </v-list-item-title>
                                <v-list-item-subtitle class="font-weight-medium">
                                    <span class="text-capitalize">{{FormatFecha(fecha)}} | Turno {{turnodatos.turno}}</span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-card flat class="pa-1">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete v-model="maquinistaseleccionado" :items="FiltroListaMaq" item-text="nombre" outlined dense label="Seleccione Maquinista" clearable append-icon="" return-object hide-details @change="DatosMaquinista(maquinistaseleccionado)" :menu-props="{ rounded:'lg', transition:'slide-y-transition'}">
                                <template v-slot:selection="data">
                                    <v-list-item-avatar class="my-0 mr-1" size="35">
                                        <v-avatar color="secondary" size="25">
                                            <v-icon size="18" color="primary">mdi-account</v-icon>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="text-capitalize py-0">
                                        <span class="text-subtitle-2 text-capitalize grey--text text--darken-1 text-truncate">{{data.item.nombre}}</span>
                                    </v-list-item-content>
                                </template>
                                <template v-slot:item="data">
                                    <v-list-item-avatar class="my-0">
                                        <v-avatar color="secondary" size="35">
                                            <v-icon size="23" color="primary">mdi-account</v-icon>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-capitalize">{{data.item.nombre}}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            Turno
                                            <span v-for="(turno, index) in data.item.turno" :key="index" class="text-uppercase">
                                                {{turno.turno}}
                                                <span v-if="index < data.item.turno.length-1"> + </span>
                                            </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card flat max-height="65vh" min-height="200" style="overflow: auto;">
                    <v-container>
                        <AsignarDetalle :turno_datos="turnodatos" modo="asignar" :servicio_lista="servicios_lista" :maquinista_datos="maquinista_datos" :fecha="fecha"/>
                        <v-row v-if="!maquinista_datos">
                            <v-col cols="12">
                                <v-card width="100%" height="140" flat>
                                    <v-row justify="center" align-content="center" class="text-center ma-0">
                                        <v-col cols="12">
                                            <v-card flat height="120" class="justify-center align-center d-flex flex-column">
                                                <v-icon size="70" color="grey lighten-1">
                                                    mdi-account-search-outline
                                                </v-icon>
                                                <span class="trenos-title-toolbar grey--text text--lighten-1">Selecciona un maquinista</span>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="CerrarAsignar()">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.editar" persistent max-width="450" transition="fade-transition" class="rounded-lg" overlay-opacity="0">
        <v-card flat class="rounded-lg" v-if="editardatos.mod">
            <v-card-title class="pa-0 modal-title">
                <v-toolbar flat class="rounded-t-lg">
                    <v-toolbar-title class="pa-0" style="line-height: 1.1;">
                        <v-list-item class="pl-0 rounded-t-lg">
                            <v-list-item-avatar class="mr-2 rounded-lg" color="secondary" tile width="8" height="30" min-width="8" />
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="headline pb-0 mb-0">
                                    <v-toolbar-title class="pa-0 text-capitalize grey--text text--darken-2" style="line-height: 1.1;">{{editardatos.mod.nombre}}</v-toolbar-title>
                                </v-list-item-title>
                                <v-list-item-subtitle class="font-weight-medium">
                                    Editar turno asignado
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-card flat class="pa-1">
                <v-card flat max-height="65vh" min-height="200" style="overflow: auto;">
                    <v-container>
                        <AsignarDetalle :turno_datos="turnodatos" modo="editar" :servicio_lista="servicios_lista" :maquinista_datos="maquinista_datos" :fecha="fecha"/>
                    </v-container>
                </v-card>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="CerrarEditar()">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-card>
</template>

<script>
import {
    mapState,
    mapMutations
} from 'vuex';
import dayjs from "dayjs";
export default {
    name: 'Asignar',
    props: ['turnodatos', 'fecha', 'edit', 'asignar'],
    components: {
        AsignarDetalle: () => import('@/components/AsignarDetalle'),
    },
    data() {
        return {
            edit_vista : 0,
            listamaq: [],
            maquinista_datos: null,
            maquinistaseleccionado: null,
            asignardatos: [],
            editardatos: {},
            extra: false,
            servicios_lista : []
        }
    },
    computed: {
        ...mapState(['usuario', 'dialog', 'sistema', 'update']),
        Edit(){
            let edit = false
            if (this.edit) edit = true
            return edit
        },
        DialogAsignar(){
            return this.dialog.asignar
        },
        FiltroListaMaq(){
            let maquinista_lista = JSON.parse(JSON.stringify(this.listamaq)),
                maquinista_asignado = this.turnodatos.asignaciones.map(id_maquinista => { return id_maquinista.id }),
                maquinista_filtro =  maquinista_lista.filter(maquinista => !maquinista_asignado.includes(maquinista.usuario_id))
            //return servicio_filtro
            return maquinista_filtro
        }
    },
    methods: {
        ...mapMutations(['ShowSnackBar', 'LogOut', 'ErrorRequest','CloseDialog']),
        FormatFecha(fecha){
            return dayjs(fecha).format('dddd DD')
        },
        //Envia los datos a la API para liberar al maquinista (L).
        async LiberarMaquinista(turno_id, usuario_id, fecha) {
            let datos = {
                turno_id,
                usuario_id,
                fecha,
                code: 0
            }
            await this.axios.post(`/planilla/edit/`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.update.turno = true
                        this.update.planilla = true
                    }
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        //Llama a la API para que ésta le devuelva la lista de maquinsitas y sus turnos asignados para la fecha consultada y abre el dialog asignar.
        async AsignarMaquinistaLista(turno_id, fecha) {
            this.asignardatos = []
            let datos = {
                fecha,
                code: 1
            }
            await this.axios.post(`/planilla/edit/`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                } else {
                    this.listamaq = respuesta.data.lista
                    this.dialog.asignar = true
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        //Abre el dialog para la edicion del maquinista asignado.
        async AbrirEdicion(turno_datos, maquinista_datos) {
            this.maquinista_datos = maquinista_datos
            this.editardatos = {}
            this.editardatos = {
                fecha: this.fecha,
                mod: maquinista_datos,
                turno_datos: {
                    inicio: turno_datos.inicio,
                    termino: turno_datos.termino,
                    entrada: turno_datos.entrada,
                    salida: turno_datos.salida,
                    duracion: turno_datos.duracion,
                    turno: turno_datos.turno,
                    id: turno_datos.id
                }
            }
            this.dialog.editar = true
        },
        //Carga los datos del maquinista sellecionado para asignar (turnos ya asigados junto con el nuevo).
        DatosMaquinista(datos) {
            if (datos) {
                this.maquinista_datos = datos
            } else {
                this.maquinista_datos = null
            }
        },
        //Cierra el dialog para asignar.
        CerrarAsignar() {
            this.maquinista_datos = null
            this.maquinistaseleccionado = null
            this.CloseDialog('asignar')
        },
        //Cerrar el dialog para editar
        CerrarEditar() {
            this.editardatos = {
                inicio: null,
                termino: null,
                entrada: null,
                salida: null,
                duracion: null,
                turno: null,
                id: null,
                servicios: []
            }
            this.dialog.editar = false
        },
        //Entrega el tipo de maquinista con base en su sigla de tipo.
        TipoMaquinsita(tipo) {
            let tipo_abreviado
            switch (tipo) {
                case 'Full Time':
                    tipo_abreviado = 'FT'
                    break;
                case 'Part Time 120':
                    tipo_abreviado = '120'
                    break;
                case 'Part Time 80':
                    tipo_abreviado = '80'
                    break;
            }
            return tipo_abreviado
        }
    },
    watch: {
        //Detecta el boton asignar maq (Turno.vue)
        asignar: function (val) {
            if (val) this.AsignarMaquinistaLista(this.turnodatos.id, this.fecha)
        },
        DialogAsignar: function (val) {
            if(!val){
                this.CerrarAsignar()
            }
        }
    }
}
</script>

<style scoped>

.v-list-item {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    letter-spacing: normal;
    min-height: 45px;
    outline: none;
    padding: 0 16px;
    position: relative;
    text-decoration: none;
}
</style>
